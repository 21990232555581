// extracted by mini-css-extract-plugin
export var all = "plasmic__default_style-module--all--NwouX";
export var img = "plasmic__default_style-module--img--d4eRd plasmic__default_style-module--all--NwouX";
export var span = "plasmic__default_style-module--span--1OFCI plasmic__default_style-module--all--NwouX";
export var input = "plasmic__default_style-module--input--3z4EB plasmic__default_style-module--all--NwouX";
export var textarea = "plasmic__default_style-module--textarea--2n2Bv plasmic__default_style-module--all--NwouX";
export var button = "plasmic__default_style-module--button--2OAT1 plasmic__default_style-module--all--NwouX";
export var code = "plasmic__default_style-module--code--qrmDz plasmic__default_style-module--all--NwouX";
export var pre = "plasmic__default_style-module--pre--1HHC9 plasmic__default_style-module--all--NwouX";
export var p = "plasmic__default_style-module--p--21ftA plasmic__default_style-module--all--NwouX";
export var h1 = "plasmic__default_style-module--h1--qml5W plasmic__default_style-module--all--NwouX";
export var h2 = "plasmic__default_style-module--h2--3nLoH plasmic__default_style-module--all--NwouX";
export var h3 = "plasmic__default_style-module--h3--2ayUN plasmic__default_style-module--all--NwouX";
export var h4 = "plasmic__default_style-module--h4--1KNLF plasmic__default_style-module--all--NwouX";
export var h5 = "plasmic__default_style-module--h5--35CvV plasmic__default_style-module--all--NwouX";
export var h6 = "plasmic__default_style-module--h6--42pkB plasmic__default_style-module--all--NwouX";
export var address = "plasmic__default_style-module--address--3PB8K plasmic__default_style-module--all--NwouX";
export var a = "plasmic__default_style-module--a--30uxQ plasmic__default_style-module--all--NwouX";
export var ol = "plasmic__default_style-module--ol--2CtmU plasmic__default_style-module--all--NwouX";
export var ul = "plasmic__default_style-module--ul--3gvW3 plasmic__default_style-module--all--NwouX";
export var select = "plasmic__default_style-module--select--OgoL- plasmic__default_style-module--all--NwouX";
export var li = "plasmic__default_style-module--li--mJJH6 plasmic__default_style-module--all--NwouX";
export var plasmic_default__component_wrapper = "plasmic__default_style-module--plasmic_default__component_wrapper--37IPe";
export var plasmic_default__inline = "plasmic__default_style-module--plasmic_default__inline--2sVyE";
export var plasmic_page_wrapper = "plasmic__default_style-module--plasmic_page_wrapper--3NE1W";